import { css, ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F7F5F0',
      neutralLight2: '#FFFFFC',
      neutralLight1: '#0F0E0E',
      primaryDark: '#62242F',
      primaryLight: '#A39565',
      dangerLight: '#DB4401',
    },
  },
  fontFamily: {
    heading: "'ivypresto-display', serif",
    paragraph: "'utile-display', sans-serif",
  },
}

export type Styles = typeof styles

export const styles = {
    img: css`
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    will-change: contents;
  `,
}

export const ThemeProvider = memo(function ThemeProvider({ children }:any) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
